import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import {
  fetchPatientQuestionnaires,
  ignoreQuestionnaire
} from "actions/questionnaires";
import { fetchComplianceForms } from "actions/compliance-forms";
import { Page, Card } from "components/ui";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { formatDateLocal } from "utils/misc";
import useTranslate from "hooks/use-translate-language";

const Questionnaires = () => {
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const questionnaires = useSelector(selectors.getPatientQuestionnaires);
  const [complianceForms, setCompliancedForms] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchPatientQuestionnaires()).finally(() => {});

    dispatch(fetchComplianceForms())
      .then(data => { setCompliancedForms(data)})
      .finally(() => {
        setIsFetching(false);
      });
  }, [dispatch]);

  return (
    <Page.Container>
      <Page.Header title={translate("questionnaires")} goBack="/" />
      <Page.Content
        isFetching={isFetching}
        isEmpty={questionnaires.length === 0}
        emptyMessage={translate("noQuestionnaires")}
      >
        {questionnaires.map(q => (
          <Card key={q.questionnaire_assignment_id} style={{ margin: 10 }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center",
                margin: "5px 0"
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h4>
                  {q.questionnaire_type === 1
                    ? translate("service")
                    : translate("product")}
                </h4>
                <div>{q.description}</div>
                <div>{formatDateLocal(q.questionnaire_date)}</div>
              </div>
              <div>
                <Button
                  onClick={async () => {
                    dispatch(
                      ignoreQuestionnaire(q.questionnaire_assignment_id)
                    );
                  }}
                >
                  {translate("ignore")}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push(
                      `/questionnaires/${q.questionnaire_assignment_id}`
                    );
                  }}
                >
                  {translate("fillOutNow")}
                </Button>
              </div>
            </div>
          </Card>
        ))}

        {complianceForms.map(q => (
          <Card key={q.FormId} style={{ margin: 10 }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center",
                margin: "5px 0"
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h4>
                  {q.Name}
                </h4>
                <div>{q.Description}</div>
                <div>{formatDateLocal(q.AssignedDate)}</div>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push(
                      `/fill-form?form=${q.FormId}`
                    );
                  }}
                >
                  {translate("fillOutNow")}
                </Button>
              </div>
            </div>
          </Card>
        ))}
      </Page.Content>
    </Page.Container>
  );
};

export default Questionnaires;
