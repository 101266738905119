const simpleOptions = strArr => strArr.map(text => ({ text, value: text }));
export default {
  // Index
  orderSupplies: { english: "Order Supplies", spanish: "Pedir suministros" },
  atLeastOne: {
    english: "Please select at least one product",
    spanish: "por favor seleccione por lo menos un producto"
  },
  pleaseSelectUsage: {
    english: "Please select usage",
    spanish: "Por favor seleccione uso"
  },
  pleaseSelectInsurance: {
    english: "Please select insurance",
    spanish: "Por favor seleccione seguro"
  },
  required: {
    english: "Required",
    spanish: "Requerido"
  },
  enterName: {
    english: "Please enter name",
    spanish: "Por favor ingrese el nombre"
  },
  requiresADysfunction: {
    english:
      "Eligibility to have insurance cover replacements of these items requires the presence of a dysfunction.",
    spanish:
      "La elegibilidad para que el seguro cubra reemplazos de estos artículos requiere la presencia de una disfunción."
  },
  enterPhoneNumber: {
    english: "Please enter phone number",
    spanish: "Ingrese el número de teléfono"
  },
  // These probably belong in profile, hastily put here to fix a build
  name: {
    english: "Name",
    spanish: "Nombre"
  },
  address: {
    english: "Address",
    spanish: "Dirección"
  },
  insurance: {
    english: "Insurance",
    spanish: "Seguro"
  },
  physician: {
    english: "Physician",
    spanish: "Médico"
  },

  // Stepper
  next: { english: "Next", spanish: "Próximo" },
  finish: { english: "Finish", spanish: "Terminar" },
  back: { english: "Back", spanish: "Volver" },

  // Choose-Supplies
  chooseSupplies: { english: "Choose Supplies", spanish: "Elija suministros" },
  issuesOrQuestions: {
    english:
      "If you expererience any issues or have any questions please call us at",
    spanish: "Si tiene algún problema o tiene alguna pregunta, llámenos al"
  },
  popular: { english: "Popular", spanish: "Popular" },
  basic: { english: "Basic", spanish: "Básico" },

  mask: { english: "Mask", spanish: "Máscara" },
  tubing: { english: "Tubing", spanish: "Tubería" },
  seals: { english: "Seals", spanish: "Sello" },
  headgear: { english: "Headgear", spanish: "El elástico" },
  filters: { english: "Filters", spanish: "Filtros" },
  "disposable filters": {
    english: "Disposable Filters",
    spanish: "Desechable Filtros"
  },
  "nondisposable filters": {
    english: "Non-Disposable Filters",
    spanish: "No Desechable Filtros"
  },
  chinstrap: { english: "Chinstrap", spanish: "Correa de la barbilla" },
  "water chamber": { english: "Water Chamber", spanish: "Contenedor de agua" },
  filter: { english: "Filters", spanish: "Filtros" },
  seal: { english: "Seals", spanish: "Sello" },
  //eligibility indicator notices
  youAreEligible: {
    english: "Indicates you are eligible for this item ",
    spanish: "Indica que eres elegible para este artículo. "
  },
  youAreNotEligible: {
    english:
      "Indicates that you are NOT currently eligible but will become eligible on the date shown. ",
    spanish:
      "Indica que NO es elegible, pero será elegible en la fecha que se muestra. "
  },
  selectingNonEligibleItem: {
    english:
      "**If you select items for which you are not eligible you may incur additional out of pocket costs. ",
    spanish:
      "**Si selecciona artículos para los que no es elegible, es posible que obtenga costos adicionales de su bolsillo. "
  },
  indicatorNotice: {
    english: "Eligibility is now indicated per item based on your insurance. ",
    spanish:
      "La elegibilidad ahora se indica por artículo. Esto se basa en su aseguranza. "
  },
  noIndicatorNotice: {
    english:
      "Items with no indicator have not been ordered through our system and we are not able to accurately determine eligibility. ",
    spanish:
      "Los artículos sin un indicador no se han pedido anteriormente a través de nuestro sistema. no podemos determinar la elegibilidad. "
  },
  supplyOptions: {
    english: [
      {
        value: "mask",
        text: "Mask",
        description:
          "Includes the frame as well as the seal/cushion/pillow of your mask and the headgear when eligible",
        replacementSchedule: "Once every 3 months",
        required: true
      },
      {
        value: "seals",
        text: "Seal / Cushion / Pillow",
        description:
          "Soft silicone replacement portion of your mask that touches your face.",
        replacementSchedule:
          "Full face: 3 per 3 months, Nasal/Pillow: 6 per 3 months.",
        required: true
      },
      {
        value: "headgear",
        text: "Headgear",
        description:
          "Neoprene Straps that go around your head and holds the mask in place",
        replacementSchedule: "Included with mask when eligible"
      },
      {
        value: "tubing",
        text: "Tubing",
        description:
          "Corrugated tubing that runs from your mask to your pap machine.",
        replacementSchedule: "Once every 3 months."
      },
      {
        value: "filter",
        text: "Filters",
        description: "Machine filter",
        replacementSchedule:
          "Disposable: 6 per 3 months – Non-Disposable (if applicable): 1 every 6 months."
      },
      {
        value: "disposable filter",
        text: "Disposable Filters",
        description: "Disposable Machine filter",
        replacementSchedule: "Disposable: 6 per 3 months "
      },
      {
        value: "nondisposable filter",
        text: "Non-Disposable Filters",
        description: "Non-Disposable Machine filter",
        replacementSchedule: "Non-Disposable: 1 every 6 months."
      },
      {
        value: "chinstrap",
        text: "Chinstrap",
        description:
          "Neoprene strap that sets under the chin to help hold your mouth closed",
        replacementSchedule: "Once every 6 months"
      },
      {
        value: "water chamber",
        text: "Water chamber",
        description: "Reservoir that holds water for humidifier",
        replacementSchedule: "Once every 6 to 12 months"
      }
    ],
    spanish: [
      {
        value: "mask",
        text: "Máscara",
        description:
          "Incluye el marco plástico, así como el sello / cojin / almohada de su máscara y el elástico cuando sea eligible",
        replacementSchedule: "Una vez cada 3 meses",
        required: true
      },
      {
        value: "seals",
        text: "Sello / Cojín / Almohada",
        description:
          "Parte de reemplazo de silicona suave de su máscara que toca su cara",
        replacementSchedule:
          "Cara completa: 3 por 3 meses, Nasal / Almohada: 6 por 3 meses",
        required: true
      },
      {
        value: "headgear",
        text: "El elástico",
        description:
          "Correas de neopreno que rodean su cabeza y mantienen la máscara en su lugar",
        replacementSchedule: "Incluido con la mascarilla cuando sea elegible"
      },
      {
        value: "tubing",
        text: "Tubería",
        description:
          "Tubo corrugado que va desde su mascarilla hasta su máquina de pap",
        replacementSchedule: "Una vez cada 3 meses"
      },
      {
        value: "filter",
        text: "Filtros",
        description: "Filtro de máquina",
        replacementSchedule:
          "Desechables: 6 por 3 meses - No desechables (si corresponde): 1 cada 6 meses"
      },
      {
        value: "disposable filter",
        text: "Disposable Filters",
        description: "Filtro de máquina",
        replacementSchedule: "Desechables: 6 por 3 meses"
      },
      {
        value: "nondisposable filter",
        text: "Non-Disposable Filters",
        description: "Non-Disposable Machine filter",
        replacementSchedule: "Non-Disposable: 1 cada 6 meses"
      },
      {
        value: "chinstrap",
        text: "Correa de la barbilla",
        description:
          "Correa de neopreno que se ajusta debajo de la barbilla para ayudar a mantener la boca cerrada",
        replacementSchedule: "Una vez cada 6 meses"
      },
      {
        value: "water chamber",
        text: "Contenedor de agua",
        description: "Depósito que contiene agua para humidificador",
        replacementSchedule: "Una vez cada 6 a 12 meses"
      }
    ]
  },

  // Dysfunction
  dysfunctions: {
    english: "Dysfunctions",
    spanish: "Disfunciones"
  },
  havingIssues: {
    english: "I am having issues with my PAP device",
    spanish: "Tengo problemas con mi dispositivo PAP"
  },
  selectIssue: {
    english: "Select Issue",
    spanish: "Seleccionar problema"
  },
  selectPapIssue: {
    english: "Select Pap Device Issue (if applicable)",
    spanish: "Seleccione el problema del dispositivo PAP (si corresponde)"
  },

  papIssuesOptions: {
    english: [
      { text: "None", value: "" },
      { text: "Humidification", value: "Humidification" },
      { text: "Machine", value: "Machine" },
      { text: "Other", value: "Other" }
    ],
    spanish: [
      { text: "Ninguno", value: "" },
      { text: "Humidificacion", value: "Humidification" },
      { text: "Máquina", value: "Machine" },
      { text: "Otro", value: "Other" }
    ]
  },
  provideInformationOrConcerns: {
    english:
      "Please include any additional information/concerns regarding your PAP therapy",
    spanish:
      "Incluya cualquier información o inquietud adicional con respecto a su terapia de PAP"
  },
  insuranceOnHand: {
    english:
      "Insurance requires us to document the amount of supplies you have on hand",
    spanish:
      "El seguro requiere que documentemos la cantidad de suministros que tiene a mano"
  },

  quantityOnHandOptions: {
    english: simpleOptions([
      "None",
      "Only the singular items currently being used and needing replacement. No additional replacements on hand",
      "I have additional stock on hand"
    ]),
    spanish: [
      { text: "Ninguno", value: "None" },
      {
        text: "Solo los elementos singulares que se utilizan actualmente y necesitan reemplazo. No hay reemplazos adicionales disponibles",
        value:
          "Only the singular items currently being used and needing replacement. No additional replacements on hand"
      },
      {
        text: "Tengo reemplazos adicionales a la mano",
        value: "I have additional stock on hand"
      }
    ]
  },

  orderEquipmentQuestions: {
    english: [
      {
        equipment: "mask",
        key: "mask_dirty",
        text: "My Mask is dirty with risk of infection."
      },
      {
        equipment: "mask",
        key: "mask_broken",
        text: "My Mask is in poor and/or non-working condition."
      },
      {
        equipment: "mask",
        key: "mask_loose",
        text: "My Mask will not adequately seal due to wear."
      },
      {
        equipment: "headgear",
        key: "headgear_loose",
        text: "My Headgear is stretched limiting pressure support."
      },
      {
        equipment: "headgear",
        key: "headgear_broken",
        text: "My Headgear is in poor and/or non-working condition."
      },
      {
        equipment: "seals",
        key: "seal_loose",
        text: "My Seal/Cushion/Pillow will not adequately seal due to wear."
      },
      {
        equipment: "seals",
        key: "seal_broken",
        text: "My Seal/Cushion/Pillow is in poor and/or non-working condition."
      },
      {
        equipment: "filter",
        key: "filter_dirty",
        text: "My Filter is dirty with risk of infection."
      },
      {
        equipment: "tubing",
        key: "tubing_dirty",
        text: "My Tubing is dirty with risk of infection."
      },
      {
        equipment: "tubing",
        key: "tubing_broken",
        text: "My Tubing is in poor and/or non-working condition."
      },
      {
        equipment: "water chamber",
        key: "waterchamber_dirty",
        text: "My Waterchamber is dirty with risk of infection."
      },
      {
        equipment: "water chamber",
        key: "waterchamber_broken",
        text: "My Waterchamber is in poor and/or non-working condition."
      },
      {
        equipment: "chinstrap",
        key: "chinstrap_broken",
        text: "My Chinstrap Is in poor and/or non-working condition."
      },
      {
        equipment: "chinstrap",
        key: "chinstrap_loose",
        text: "My Chinstrap is stretched, limiting pressure support."
      }
    ],
    spanish: [
      {
        equipment: "mask",
        key: "mask_dirty",
        text: "Mi máscara está sucia con riesgo de infección."
      },
      {
        equipment: "mask",
        key: "mask_broken",
        text: "Mi máscara está en malas condiciones o no funciona."
      },
      {
        equipment: "mask",
        key: "mask_loose",
        text: "Mi máscara no se sella adecuadamente debido al desgaste."
      },
      {
        equipment: "headgear",
        key: "headgear_loose",
        text: "Mi arnés está estirado limitando la presión de soporte."
      },
      {
        equipment: "headgear",
        key: "headgear_broken",
        text: "Mi arnés está en malas condiciones o no funciona."
      },
      {
        equipment: "seals",
        key: "seal_loose",
        text: "Mi sello / cojín / almohada no sellará adecuadamente debido al desgaste."
      },
      {
        equipment: "seals",
        key: "seal_broken",
        text: "Mi sello / cojín / almohada está en malas condiciones o no funciona."
      },
      {
        equipment: "filter",
        key: "filter_dirty",
        text: "Mi filtro está sucio con riesgo de infección."
      },
      {
        equipment: "tubing",
        key: "tubing_dirty",
        text: "Mi tubería está sucia con riesgo de infección."
      },
      {
        equipment: "tubing",
        key: "tubing_broken",
        text: "Mi tubería está en malas condiciones o no funciona."
      },
      {
        equipment: "water chamber",
        key: "waterchamber_dirty",
        text: "Mi contenedor de agua está sucia con riesgo de infección."
      },
      {
        equipment: "water chamber",
        key: "waterchamber_broken",
        text: "Mi contenedor de agua está en malas condiciones o no funciona."
      },
      {
        equipment: "chinstrap",
        key: "chinstrap_broken",
        text: "Mi barbijo está en malas condiciones o no funciona."
      },
      {
        equipment: "chinstrap",
        key: "chinstrap_loose",
        text: "Mi barbijo está estirado, lo que limita la presión de soporte."
      }
    ]
  },

  // Usage
  equipmentUsage: {
    english: "Tell us about your equipment usage",
    spanish: "Cuéntenos sobre el uso de su equipo"
  },
  hoursPerNightQuestion: {
    english: "How many hours per night are you using PAP therapy?",
    spanish: "¿Cuántas horas por noche usa la terapia PAP?"
  },
  nightsPerWeekQuestion: {
    english: "How many nights per week are you using PAP therapy?",
    spanish: "¿Cuántas noches a la semana usa la terapia PAP?"
  },
  adequateUseOfPAP: {
    english:
      "Adequate use of PAP device is recommended for your health and insurance coverage of supplies.",
    spanish:
      "Se recomienda el uso adecuado del dispositivo PAP para la cobertura de suministros de su seguro médico y de salud."
  },
  sawSleepPhysician: {
    english: "I saw my sleep physician in the last",
    spanish: "Vi a mi medica del sueño en la ultima"
  },
  hoursPerNightOptions: {
    english: simpleOptions(["less than 4 hours", "4-6 hours", "6+ hours"]),

    spanish: [
      { text: "menos de 4 horas", value: "less than 4 hours" },
      { text: "4-6 horas", value: "4-6 hours" },
      { text: "6+ horas", value: "6+ hours" }
    ]
  },
  lastSawPhysicianOptions: {
    english: simpleOptions([
      "0-3 months",
      "3-6 months",
      "6-12 months",
      "12 or more months"
    ]),
    spanish: [
      { text: "0-3 meses", value: "0-3 months" },
      { text: "3-6 meses", value: "3-6 months" },
      { text: "6-12 meses", value: "6-12 months" },
      { text: "12 o más meses", value: "12 or more months" }
    ]
  },
  // Eligibility-Info
  eligibleForSupplies: {
    english: "Eligible for Supplies",
    spanish: "Elegible para suministros"
  },
  youAreNotDue: {
    english:
      "Our records show you are not due, this order might not be proccessed.",
    spanish:
      "Nuestros registros muestran que no es debido, es posible que este pedido no se procese."
  },

  // Insurance
  insuranceHasChanged: {
    english: "My insurance information has changed since my last order",
    spanish: "La información de mi seguro ha cambiado desde mi último pedido"
  },
  myCurrentInsurance: {
    english: "My current insurance",
    spanish: "Mi seguro actual"
  },
  mySubscriberId: {
    english: "My subscriber ID is",
    spanish: "Mi ID de suscriptor es"
  },
  // Physician
  enterInformation: {
    english: "Please enter as much information as you can",
    spanish: "Ingrese toda la información que pueda"
  },
  myCurrentPhysician: {
    english: "My current sleep physician is",
    spanish: "Mi médico del sueño actual es"
  },
  sleepPhysicianAddress: {
    english: "Sleep Physician Address",
    spanish: "Dirección del médico del sueño"
  },
  additionalInformation: {
    english: "Additional Information",
    spanish: "Información adicional"
  },
  streetAddress: {
    english: "Street Address",
    spanish: "Dirección"
  },
  streetAddress2: {
    english: "Street Address 2",
    spanish: "Detalles de envío dos"
  },
  city: {
    english: "City",
    spanish: "Ciudad"
  },
  zip: {
    english: "Zip",
    spanish: "Código postal"
  },
  state: {
    english: "State",
    spanish: "Estado"
  },
  physicianPhoneNumber: {
    english: "Physician Phone Number",
    spanish: "Número de teléfono del médico"
  },
  physicianFaxNumber: {
    english: "Physician Fax Number",
    spanish: "Número de fax del médico"
  },
  // Summary
  orderSummary: {
    english: "Order Summary",
    spanish: "Resumen de la orden"
  },
  sendThreeMonthSupply: {
    english:
      "Please send me a three-month supply if insurance currently allows",
    spanish:
      "Por favor envíeme un suministro para tres meses si el seguro lo permite actualmente"
  },
  youWillReceive: {
    english: "You will receive a ",
    spanish: "Recibirás un"
  },
  daySupplyOfItems: {
    english: "day supply of the following items",
    spanish: "suministro de los siguientes artículos"
  },
  orderFormWarning: {
    english:
      "I understand that this order request for supplies will be billed through insurance and is subject to copay and deductible as well as screening for eligibility of supplies. A current prescription, recent doctor visit and compliance download may be needed for order processing. A representative from our verification team will contact you if additional documentation is needed. Changes may occur to the supplies ordered in the verification process.",
    spanish:
      "Entiendo que esta solicitud de pedido de suministros se facturará a través de seguro y está sujeto a copagos y deducibles, así como a exámenes de detección para la elegibilidad de suministros. Una receta actual, visita médica reciente y la descarga de  los datos del uso de la máquina puede ser necesaria para el procesamiento de pedidos. Un representante de nuestro equipo de verificación se comunicará con usted si se necesita documentación adicional. Pueden ocurrir cambios en los suministros ordenados en el proceso de verificación."
  },
  // Shipping Details
  shippingDetails: {
    english: "Shipping Details",
    spanish: "Detalles de envío"
  },
  personPlacingOrder: {
    english: "Person placing this order is",
    spanish: "La persona que realiza este pedido es"
  },
  officePickupQuestion: {
    english: "Would you prefer to pick up your order from the office?",
    spanish: "¿Prefieres recoger tu pedido en la oficina?"
  },
  patientOrCaregiver: {
    english: [
      { text: "Patient", value: "patient" },
      { text: "Caregiver", value: "caregiver" }
    ],
    spanish: [
      { text: "Paciente", value: "patient" },
      { text: "Cuidador", value: "caregiver" }
    ]
  },
  caregiverName: {
    english: "Caregiver's name",
    spanish: "Nombre del cuidador"
  },
  // Reorder

  // Banner
  orderedOn: {
    english: "Ordered On:",
    spanish: "Pedido en:"
  },
  supplies: {
    english: "Supplies",
    spanish: "Suministros"
  },
  reorderPastSupplies: {
    english: "Reorder Past Supplies:",
    spanish: "Reordenar suministros anteriores:"
  },

  // Duplicate Order Form
  reorderSupplies: {
    english: "Reorder Supplies",
    spanish: "Reordenar suministros"
  },
  placeOrder: {
    english: "Place Order",
    spanish: "Realizar pedido"
  },
  reviewAndVerify: {
    english: "Please Review And Verify the following information",
    spanish: "Revise y verifique la siguiente información"
  },
  usage: {
    english: "Usage",
    spanish: "Uso"
  },
  tellUsUsage: {
    english: "Tell us about your usage",
    spanish: "Cuéntenos sobre su uso"
  },
  personalInfo: {
    english: "Personal Info",
    spanish: "Información personal"
  },
  phoneNumber: {
    english: "Phone Number",
    spanish: "Número de teléfono"
  },
  areYouSureYouWantToCancel: {
    english: "Are you sure you want to cancel your order?",
    spanish: "¿Estás segura de que deseas cancelar tu pedido?"
  },
  confirmCancel: {
    english: "Are you sure you want to cancel?",
    spanish: "¿Estás segura de que quieres cancelar?"
  }
};
