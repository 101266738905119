import { memo, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@mui/material";

// https://material-ui.com/components/dialogs/
const Modal = memo(
  ({ renderTrigger, renderContent, children, startOpen, onClose, ...rest }) => {
    const [isOpen, setIsOpen] = useState(startOpen);
    function openModal() {
      setIsOpen(true);
    }

    function closeModal() {
      setIsOpen(false);
    }
    const stopPropagationForTab = (event) => {
      if (event.key === "Tab") {
        event.stopPropagation();
      }
    };
    return (
      <div>
        {renderTrigger({ openModal })}
        <Dialog
          onClose={() => {
            if (typeof onClose == "function") onClose();
            setIsOpen(false);
          }}
          open={isOpen}
          fullWidth
          maxWidth="sm"
          onKeyDown={stopPropagationForTab}
          {...rest}
        >
          {renderContent && renderContent({ closeModal })}
          {children}
        </Dialog>
      </div>
    );
  }
);

export default Modal;

Modal.propTypes = {
  renderTrigger: PropTypes.func.isRequired,
  renderContent: PropTypes.func,
  children: PropTypes.node,
  startOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
Modal.defaultProps = {
  startOpen: false,
};
