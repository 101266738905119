import React, { useState } from "react";
import { CheckboxInput, RadioInput } from "components/form";
import useTranslate from "hooks/use-translate-language";

const LogicalQuestion = ({
  questionId,
  questionText,
  hasNAOption,
  setFieldValue
}) => {
  const translate = useTranslate();
  const [markedAsNA, setMarkedAsNA] = useState(false);
  const [value, setValue] = useState();

  const toggleNA = () => {
    const newVal = !markedAsNA;
    setMarkedAsNA(newVal);
    setValue("");
    setFieldValue(questionId, {
      QuestionId: questionId,
      QuestionOptionIdList: null,
      MarkedAsNA: newVal,
      NumericAnswer: null
    });
  };

  const handleChange = e => {
    setFieldValue(questionId, {
      QuestionId: questionId,
      QuestionOptionIdList: null,
      MarkedAsNA: false,
      TextAnswer: null,
      NumericAnswer: e.target.value
    });
  };

  return (
    <>
      <RadioInput
        name={questionId}
        options={[
          { text: translate("no"), value: 0 },
          { text: translate("yes"), value: 1 }
        ]}
        label={questionText}
        value={value}
        onChange={handleChange}
        disabled={markedAsNA}
      />
      {hasNAOption && (
        <CheckboxInput
          name={questionId + "N/A"}
          label="N/A"
          onChange={toggleNA}
          value={markedAsNA}
        />
      )}
    </>
  );
};

export default LogicalQuestion;
