import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { TextField } from "@mui/material";
import { path } from "ramda";

const getPath = name => name.split(".");

// https://material-ui.com/api/text-field/
const TextInput = ({ label, name, ...rest }) => {
  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched
  } = useFormikContext();
  const namePath = getPath(name);
  const error = path(namePath, touched) && path(namePath, errors);
  const value = path(namePath, values);
  return (
    <TextField
      error={!!error}
      label={label}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={error}
      margin="normal"
      style={{ width: "100%" }}
      {...rest}
    />
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default TextInput;
