import React from "react";
import FillOutComplianceForms from "./fill-out-compliance-form";
import { AuthorizePatient } from "components/new-email-order/authorize-patient";
import { useSelector } from "react-redux";
import selectors from "selectors";

const ComplianceForm = () => {

  const authToken = useSelector(selectors.getAuthenticationToken);
  return (
    authToken ?
    <FillOutComplianceForms />
    :
    <AuthorizePatient>
      <FillOutComplianceForms />
    </AuthorizePatient>
  );
};

export default ComplianceForm;
