import { useEffect } from "react";
import { useFormikContext, Field } from "formik";
import { find, uniq } from "ramda";
import { FormHelperText } from "@mui/material";
import {
  TextInput,
  SelectInput,
  CheckboxInput,
  RadioGroupField
} from "components/form";
import useTranslate from "hooks/use-translate-language";
import { useSelector } from "react-redux";
import selectors from "selectors";

const Dysfunctions = () => {
  const translate = useTranslate();
  const { values, errors, setFieldValue } = useFormikContext();
  // useEffect(() => {
  //   setFieldValue("issue_type", "");
  // }, [values.issues_with_pap_device, setFieldValue]);

  const getEquipmentQuestions = equipment =>
    translate("orderEquipmentQuestions").filter(
      question => question.equipment === equipment
    );
  useEffect(() => {
    if (values.issue_type === "") {
      setFieldValue("issues_with_pap_device", false);
    } else setFieldValue("issues_with_pap_device", true);
  }, [values.issue_type]);

  const companyOrderFormOptions = useSelector(
    selectors.getCompanyOrderFormOptions
  );

  useEffect(() => {
    //Make sure to send null if we don't actually ask the question
    if (companyOrderFormOptions.quantity_on_hand === false)
      setFieldValue("quantity_on_hand", null);
  }, [companyOrderFormOptions.quantity_on_hand]);

  return (
    <div className="order-form-page">
      <h4>{translate("dysfunctions")}</h4>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          marginTop: 10
        }}
      >
        <div style={{ padding: 15 }}>
          <SelectInput
            name="issue_type"
            label={translate("selectPapIssue")}
            options={translate("papIssuesOptions")}
          />
        </div>

        {values.insurances.some(x => x?.requires_dysfunctions ?? true) && (
          <div>
            {uniq(
              values.requested_equipment.map(equipment =>
                equipment == "nondisposable filter" ||
                equipment == "disposable filter"
                  ? "filter"
                  : equipment
              )
            ).map(equipment => (
              <div
                key={equipment}
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  background: "white",
                  borderRadius: 4,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <h3 style={{ color: "#333", padding: 5 }}>
                  {
                    find(
                      ({ value }) => value === equipment,
                      translate("supplyOptions")
                    )?.text
                  }
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1
                  }}
                >
                  {getEquipmentQuestions(equipment).map(question => (
                    <div style={{ padding: 5 }} key={question.key}>
                      <CheckboxInput
                        label={question.text}
                        name={question.key}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        <div
          style={{
            height: 40,
            display: "flex",
            alignItems: "center"
          }}
        >
          <FormHelperText
            error
            style={{
              fontSize: "1.15em"
            }}
          >
            {errors.dysfunctions}
          </FormHelperText>
        </div>
        {companyOrderFormOptions.washing_supplies && (
          <Field
            component={RadioGroupField}
            name="washing_supplies"
            label="To extend the use of your supplies in between replacement periods, are you washing your supplies with warm water and soap?"
            value={values.washing_supplies}
            options={[
              { value: true, text: "Yes" },
              { value: false, text: "No" }
            ]}
          />
        )}
        <TextInput
          name="other_issues"
          label={translate("provideInformationOrConcerns")}
          style={{ width: "100%", marginBottom: 30 }}
        />
        {companyOrderFormOptions.quantity_on_hand && (
          <SelectInput
            name="quantity_on_hand"
            label={translate("insuranceOnHand")}
            options={translate("quantityOnHandOptions")}
            style={{ width: "100%" }}
          />
        )}
        {companyOrderFormOptions.hospice_care && (
          <Field
            component={RadioGroupField}
            name="inpatient_facility"
            label="Are you currently under Hospice care or in an inpatient care facility such as Hospital, Nursing Home, or other inpatient facilities?"
            value={values.inpatient_facility}
            options={[
              { value: true, text: "Yes" },
              { value: false, text: "No" }
            ]}
          />
        )}
        {companyOrderFormOptions.received_supplies_from_provider && (
          <Field
            component={RadioGroupField}
            name="outside_purchase"
            label="Have you received pap supplies from any other provider in the past 90 days?"
            value={values.outside_purchase}
            options={[
              { value: true, text: "Yes" },
              { value: false, text: "No" }
            ]}
          />
        )}
        {companyOrderFormOptions.changes_since_last_order && (
          <Field
            component={RadioGroupField}
            name="changes_since_last_order"
            label="Has there been any change of supplies since your last order?"
            value={values.changes_since_last_order}
            options={[
              { value: true, text: "Yes" },
              { value: false, text: "No" }
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default Dysfunctions;
