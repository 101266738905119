import { useEffect } from "react";
import PropTypes from "prop-types";
import { TextInput, SelectInput, RadioGroupField } from "components/form";
import { useFormikContext, Field } from "formik";
import useTranslate from "hooks/use-translate-language";
import { STATES } from "utils/enum";

const Physician = ({ physician_information }) => {
  const translate = useTranslate();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.changed_address === false) {
      setFieldValue("physician", {
        npi: physician_information?.npi ?? "",
        name: physician_information?.name ?? "",
        address: physician_information?.address ?? {
          street_address: "",
          city: "",
          zip: "",
          state: ""
        },
        phone: physician_information?.phone ?? "",
        fax: physician_information?.fax ?? ""
      });
    }
  }, [values.changed_physician]);
  return (
    <div
      style={{ display: "flex", flexDirection: "column", flex: 1, padding: 5 }}
    >
      <h4>{translate("additionalInformation")}</h4>
      <sub style={{ margin: 10, color: "#aaa" }}>
        {translate("enterInformation")}
      </sub>
      <Field
        component={RadioGroupField}
        name="changed_physician"
        label="My physician has changed or what we have on file is incorrect."
        value={values.changed_address}
        options={[
          { value: true, text: "Yes" },
          { value: false, text: "No" }
        ]}
      />
      <TextInput
        name="physician.name"
        label={translate("myCurrentPhysician")}
        style={{ marginBottom: 20 }}
        disabled={!values.changed_physician}
      />
      <TextInput
        name="physician.npi"
        label="NPI #"
        style={{ marginBottom: 20 }}
        disabled={!values.changed_physician}
      />
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <div style={{ color: "blue", marginTop: 5, marginBottom: 5 }}>
          {translate("sleepPhysicianAddress")}
        </div>
        <TextInput
          name="physician.address.street_address"
          label={translate("streetAddress")}
          style={{ marginBottom: 10 }}
          disabled={!values.changed_physician}
        />
        <TextInput
          name="physician.address.city"
          label={translate("city")}
          style={{ marginBottom: 10 }}
          disabled={!values.changed_physician}
        />
        <TextInput
          name="physician.address.zip"
          label={translate("zip")}
          style={{ marginBottom: 10 }}
          disabled={!values.changed_physician}
        />
        <SelectInput
          name="physician.address.state"
          label={translate("state")}
          options={STATES}
          style={{ marginBottom: 20 }}
          disabled={!values.changed_physician}
        />
        <TextInput
          name="physician.phone"
          label={translate("physicianPhoneNumber")}
          disabled={!values.changed_physician}
        />
        <TextInput
          name="physician.fax"
          label={translate("physicianFaxNumber")}
          disabled={!values.changed_physician}
        />
      </div>
    </div>
  );
};

export default Physician;

Physician.propTypes = {
  physician_information: PropTypes.object
};
