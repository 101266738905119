import React, { useState } from "react";
import { CheckboxInput, TextInput } from "components/form";

const FreeTextQuestion = ({
  questionText,
  questionId,
  type,
  setFieldValue,
  hasNAOption
}) => {
  const [value, setValue] = useState("");
  const [markedAsNA, setMarkedAsNA] = useState(false);

  const toggleNA = () => {
    const newVal = !markedAsNA;
    setMarkedAsNA(newVal);
    setValue("");
    setFieldValue(questionId, {
      QuestionId: questionId,
      QuestionOptionIdList: null,
      MarkedAsNA: newVal,
      TextAnswer: null,
      NumericAnswer: null
    });
  };

  const handleChange = e => {
    if (type == "number") {
      if (e.target.value < 0) return;

      setFieldValue(questionId, {
        QuestionId: questionId,
        QuestionOptionIdList: null,
        MarkedAsNA: false,
        TextAnswer: null,
        NumericAnswer: e.target.value
      });
    } else
      setFieldValue(questionId, {
        QuestionId: questionId,
        QuestionOptionIdList: null,
        MarkedAsNA: false,
        TextAnswer: e.target.value
      });

    setValue(e.target.value);
  };

  return (
    <>
      <TextInput
        name={questionId}
        label={questionText}
        type={type}
        value={value}
        onChange={handleChange}
        disabled={markedAsNA}
      />
      {hasNAOption && (
        <CheckboxInput
          name={questionId + "N/A"}
          label="N/A"
          onChange={toggleNA}
          value={markedAsNA}
        />
      )}
    </>
  );
};

export default FreeTextQuestion;
