import React, { useState } from "react";
import { CheckboxInput, SelectInput } from "components/form";
import OutlinedInput from "@mui/material/OutlinedInput";

const MultiSelectQuestion = ({
  questionId,
  questionText,
  options,
  setFieldValue,
  hasNAOption
}) => {
  const [values, setValues] = useState([]);
  const [markedAsNA, setMarkedAsNA] = useState(false);

  const setQuestionValue = e => {
    const value = e.target.value;
    console.log(value);
    setFieldValue(questionId, {
      QuestionId: questionId,
      QuestionOptionIdList: value,
      MarkedAsNA: false
    });
    setValues([...value]);
    setMarkedAsNA(false);
  };

  const toggleNA = () => {
    const newVal = !markedAsNA;
    setMarkedAsNA(newVal);
    setValues([]);
    setFieldValue(questionId, {
      QuestionId: questionId,
      QuestionOptionIdList: null,
      MarkedAsNA: newVal
    });
  };

  return (
    <>
      <SelectInput
        name={questionId}
        options={options.map(o => ({
          text: o.Text,
          value: o.FormQuestionOptionsID
        }))}
        label={questionText}
        multiple
        input={<OutlinedInput label="Tag" />}
        value={values}
        onChange={e => {
          setQuestionValue(e);
        }}
        disabled={markedAsNA}
      />
      {hasNAOption && (
        <CheckboxInput
          name={questionId + "N/A"}
          label="N/A"
          onChange={toggleNA}
          value={markedAsNA}
        />
      )}
    </>
  );
};

export default MultiSelectQuestion;
